<template>
	<div :class="isAdded ? 'active' : ''" class="qcm-wrapper">
	
		<div class="question-container no-cursor" >
			<p class="calcul" ref="question" v-html="question.question">
			</p>
			<button @click="add" class="submit add" :class="isAdded ? 'added' : ''">
				<span v-if="isAdded"> - Supprimer</span>
				<span v-else> + Ajouter</span>
			</button>
		</div>
		<QCMImage v-if="question.image && showImg" :image="question.image"/>
		
		<div class="answer-container active" :class="isDropDownVisible ? 'active' : ''">
			<p v-for="(answer) in question.answers" :key="answer.id">
				<span :class="answer.is_correct ? 'correct' : ''">
					<input type="checkbox" :checked="answer.is_correct"> <span v-html="answer.answer"></span>  
				</span>
			</p>
		</div>
		
		<Teleport to="body">
			<pop-up-preview-qcm-question v-if="isPopUpPreviewQcmVisible" :question="question" @close="showPopUpPreviewQcm"/>
		</Teleport>
	</div>
	
</template>

<script>
import PopUpPreviewQcmQuestion from '../../../PopUp/QCM/PopUpPreviewQcmQuestion.vue'
import QCMImage from '../../Resources/Resource/QCM/QCMImage.vue'

export default {
	components: { PopUpPreviewQcmQuestion, QCMImage },
	
	props: {
		added: Boolean,
		question: Object,	
		showImg: {
			type: Boolean,
			default: true
		}
	},
	data(){
		return {
			displayedImg: '',
			isAdded: this.added,
			isPopUpPreviewQcmVisible: false,
			isDropDownVisible: false
		}
	},

	methods:{
		
		toggleDropDown(){
			this.isDropDownVisible = !this.isDropDownVisible
		},
		add() {
			
			this.isAdded = !this.isAdded
			this.$emit('addQuestion', this.question)

		},
		color(e) {
			let color
			let width
			switch (e) {
				case 'Facile':
					color = '#09E300'
					width = '33%'
					break
				case 'Moyen':
					color = '#FF9300'
					width = '66%'
					break
				case 'Difficile':
					color = '#FF0000'
					width = '100%'
					break
				default:
					break
			}
			return `background-color: ${color};width: ${width};`
		},
		
		showPopUpPreviewQcm(){
			this.isPopUpPreviewQcmVisible = !this.isPopUpPreviewQcmVisible
		}
	},
	watch: {
		added() {
			this.isAdded = this.added
		},
		
	},
	
	
}
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/admin/qcm/qcm-container.scss';
</style>
